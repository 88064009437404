export default {
  // Option pour modifier le comportement du clic sur un titre d'article dans le search :
  // 'newTab' > ouvre l'info dans un nouvel onglet (pas de bouton pour ouvrir dans le complet)
  // 'complete' > ouvre le complet
  CLICK_ON_DOCS_TITLE_BEHAVIOR: 'newTab',
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        COOCCURRENCES_FIELDS: [
          { name: 'Personnes', value: 'QES_Person.verbatim', active: true },
          { name: 'Sociétés', value: 'QES_Company.verbatim', active: true },
          { name: 'Concepts', value: 'QES_ConceptCategorized.verbatim', active: true },
          { name: 'Organisations non officielles', value: 'QES_Organonoff.verbatim' },
          { name: 'Organisations', value: 'QES_Organization.verbatim' },
          { name: 'Évènements', value: 'QES_Event.verbatim' },
        ],
      },
    },
  },
  DYNAMIC_DASHBOARD: false,
};
